.footer {
    flex-shrink: 0; /* This will prevent the footer from shrinking */
    width: 100%;
    height: 2.5vh;
    border-top: 1px solid var(--active-highlight-color);
  }
  

.copyright {
    padding: 0.15em 1em;
    background-color: var(--background-secondary);
    }

.copyright p {
    font-size: 0.7em;
    text-align: right;
    }