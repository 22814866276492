.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1vw;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.highlight {
  color: var(--green);
}

.nav-icon {
  width: 48px;
  height: 48px;
}

button, span {
  font-family: 'Roboto Mono', monospace;
}

.nav-links {
  color: var(--lightest-slate);
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-right: 1vw;
  position: relative;
  overflow: hidden; 
}

.resume-button {
  color: var(--green);
  background-color: transparent;
  border: 1px solid var(--green);
  cursor: pointer;
  margin-right: 1vw;
  border-radius: 4px;
  padding: 0.75rem 1.3rem;
  transition: 0.25s;
}

.resume-button:hover {
  outline: none;
  box-shadow: 3px 3px 0 0 var(--green);
  transform: translate(-4px, -4px);
}

.nav-links::after {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 1px;
  background-color: var(--green);
  transition: width 0.3s ease;
}

.nav-links:hover::after {
  width: 100%;
}

.navbar-container {
  transition: transform 0.3s ease-out;
}

.navbar-hide {
  transform: translateY(-100%);
}
