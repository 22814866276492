:root {
    --font-sans: 'Inter', 'San Francisco', 'SF Pro Text', system-ui, sans-serif;
    --font-mono: 'Fira Code', 'Fira Mono', 'Roboto Mono', monospace;

    --dark-navy: #020c1b;
    --navy: #0a192f;
    --light-navy: #112240;
    --lightest-navy: #233554;
    --navy-shadow: rgba(2, 12, 27, 0.7);
    --dark-slate: #495670;
    --slate: #8892b0;
    --light-slate: #a8b2d1;
    --lightest-slate: #ccd6f6;
    --white: #e6f1ff;
    --green: #64ffda;
    --green-tint: rgba(100, 255, 218, 0.1);
    --pink: #f57dff;
    --blue: #57cbff;
}

body {
    background-color: var(--navy);
    margin: 0;
    font-family: var(--font-mono);

}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 25%;
    margin-right: 25%;
}


/* This styles the main scrollbar track */
::-webkit-scrollbar {
    width: 12px;
}

/* This styles the draggable scrolling element (thumb) */
::-webkit-scrollbar-thumb {
    background-color: var(--dark-slate);
    border: 3px solid var(--navy);
    border-radius: 10px;
}


::-webkit-scrollbar-track {
    background: var(--navy);
}

p {
    color: var(--slate);
    font-size: clamp(14px, 3.5vw, 14px);
    margin: 0;
}

h1 {
    color: var(--lightest-slate);
    font-size: clamp(52px, 10vw, 52px);
    margin: 2px;
}

h2 {
    color: var(--light-slate);
    font-size: clamp(46px, 8vw, 46px);
    margin: 2px;
}

h3 {
    color: var(--light-slate);
    font-size: clamp(24px, 4vw, 24px);
    margin: 2px;
}


.heading-container {
    display: flex;
    align-items: center;
}

.heading::after {
    content: "";
    display: inline-block;
    height: 2px;
    width: 15vw;  /* Adjust as needed */
    background-color: var(--lightest-slate);
    margin-left: 10px;
    vertical-align: middle;  /* This will vertically center the line with respect to the text */
}


a {
    color: var(--green); /* Default link color */
    text-decoration: none; /* Removes the default underline */
    position: relative; /* Allows positioning of pseudo-elements relative to the link */
    display: inline-block; /* Required to correctly position the pseudo-element */
}

/* Creates the underline using the ::before pseudo-element */
a::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%; /* Initial width of 0% */
    height: 1px; /* Thickness of the underline */
    background-color: var(--green); /* Color of the underline */
    transition: width 0.3s ease; /* Animation for the underline's width */
}

/* Expands the underline to 100% width on hover */
a:hover::before {
    width: 100%;
}

/* Removes styles for visited and active link states */
a:visited, a:active {
    color: var(--green); /* Keeps the color consistent with the default link color */
}

