.carousel {
    overflow: hidden;
    max-width: 2496px; /* Adjust to your image total width, here 4*624px assuming each image has a width of 624px */
    position: relative;
    border-left: 0.5vw solid  var(--active-highlight-color);
    border-right: 0.5vw solid  var(--active-highlight-color);
}

.carousel-inner {
    white-space: nowrap;
    transition: ease 1s;
}

.carousel-item {
    display: inline-block;
    width: 25%; /* Adjust to your image width, assuming it's 1/4 of the carousel width */
}

.carousel-control {
    background-color: var(--active-highlight-color);
    border: none;
    display: inline-block;
    position: absolute;
    height: 7.5%;
    width: 10.5%;
    top: calc(50% - 25px);
    color: var(--active-button-text-color);
    cursor: pointer;
    opacity: 0.5;
}

.left {
    left: 0;
}

.right {
    right: 0;
}

.carousel-indicators {
    position: absolute;
    transform: translate(-50%);
    left: 50%;
    bottom: 1.5em;
    z-index: 2;
}

.carousel-indicator-item {
    width: 15px;
    height: 15px;
    border: none;
    background: var(--active-highlight-color);
    opacity: 0.5;
    margin: 0.2em;
    border-radius: 50%;
    cursor: pointer;
}

.active {
    opacity: 1;
}

