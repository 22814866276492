.project-card {
    display: flex;
    flex-direction: column; 

    aspect-ratio: 1 / 1; /* This ensures a square shape */
    width: 100%; /* This will make it responsive, but it will respect the min and max constraints */
    min-width: 300px;
    max-width: 350px;
    min-height: 300px;
    max-height: 350px;
    
    border-radius: 5px; /* Barely rounded corners */
    padding: 16px;
    margin: 16px;
    transition: box-shadow 0.3s ease-in-out; 
    box-shadow: 
        3px 3px 0px 0px #ccc,  /* First shadow, closer to the card */
        6px 6px 0px 0px #aaa;  /* Second shadow, further from the card */
    border: 2px solid #ccc;
}

.icon-container {
    display: flex;
    justify-content: space-between; /* This will push the icons to the far ends */
    align-items: center; /* This will vertically center the icons */
}

.icon-container a {
    display: flex; /* This ensures the icons respect the flex properties */
    align-items: center; /* This will vertically center the icons */
}

.project-card img {
    width: clamp(20px, 5vw, 40px);  /* min: 20px, preferred: 5vw, max: 100px */
    height: clamp(20px, 5vh, 40px); /* min: 20px, preferred: 5vh, max: 100px */
    aspect-ratio: 1 / 1;
    margin-bottom: 1.5vh;
}


.project-card:hover {
    /* Raise the card up */
    transform: translateY(-5px);
    /* Adjust the box-shadow to give a more pronounced effect */
    box-shadow: 
        5px 5px 0px 0px #333,  /* First shadow, closer to the card */
        10px 10px 0px 0px #111;  /* Second shadow, further from the card */
    /* Optional: Add a background color or border to highlight the card */
    background-color: #f9f9f9;  /* This is a very light gray, adjust as needed */
}

.project-card p {
    flex-grow: 1;
    font-size: clamp(12px, 2vw, 18px);
}

.project-card h3 {
    padding-bottom: 10px; /* Some spacing between the border and the tags */
    padding-top: 10px;
    background-color: #D3F2FF;
    margin: 0;
    padding-left: 2.5%;
    padding-right: 2.5%;
}

.project-card .project-tags {
    border-top: 1px solid #ccc; /* Example of a top border */
    padding-top: 10px; /* Some spacing between the border and the tags */
}

.tag {
    margin-right: 15%;
    color: #7D7D7D;
}

.project-card hr {
    color: black;
    width: 100%;
}